'use strict'
angular.module('cb.leadprofile-service', [])
	.factory('LeadProfileService', ["ApiService", function (ApiService) {

		var leadProfileService = {
			getPendingLeads: function (data) {
				try {
					var url = '/webapi/api/v1/leadProfile/getPendingLeads';
					return ApiService.promisePost(url, data);
				} catch (e) {
					console.error('Error!', e);
				}
			},

			getQuotedFollowupLead: function (data) {
				try {
					var url = '/webapi/api/v1/leadProfile/getQuotedFollowupLead';
					return ApiService.promisePost(url, data);
				} catch (e) {
					console.error('Error!', e);
				}
			},

			getQuotedFollowupLeadFiltered: function (data) {
				try {
					var url = '/webapi/api/v1/leadProfile/getQuotedFollowupLeadFiltered';
					return ApiService.promisePost(url, data);
				} catch (e) {
					console.error('Error!', e);
				}
			},

			getDashboardActions: function (data) {
				try {
					var url = '/webapi/api/v1/leadProfile/getDashboardAction';
					return ApiService.promisePost(url, data);
				} catch (e) {
					console.error('Error!', e);
				}
			},

			getUnquotedFollowupLead: function (data) {
				try {
					var url = '/webapi/api/v1/leadProfile/getUnquotedFollowupLead';
					return ApiService.promisePost(url, data);
				} catch (e) {
					console.error('Error!', e);
				}
			},

			getUnquotedFollowupLeadFiltered: function (data) {
				try {
					var url = '/webapi/api/v1/leadProfile/getUnquotedFollowupLeadFiltered';
					return ApiService.promisePost(url, data);
				} catch (e) {
					console.error('Error!', e);
				}
			},

			getEnquiryById: function (id) {
				try {
					var url = '/webapi/api/v1/leadProfile/viewEnquiry/' + id;
					return ApiService.promiseGet(url);
				} catch (e) {
					console.error('Error!', e);
				}
			},

			getLeadById: function (id) {
				try {
					var url = '/webapi/api/v1/leadProfile/viewLead/' + id;
					return ApiService.promiseGet(url);
				} catch (e) {
					console.error('Error!', e);
				}
			},
			getLeadsByLeadProvider: function (id, data, sort) {
				try {
					var url = '/webapi/api/v1/leadProfile/getleads/' + id +'/' + data + '/' + sort;
					return ApiService.promiseGet(url);
				} catch (e) {
					console.error('Error!', e);
				}
			},

			getMemos: function (id) {
				try {
					var url = '/webapi/api/v1/leadProfile/viewMemos/' + id;
					return ApiService.promiseGet(url);
				} catch (e) {
					console.error('Error!', e);
				}
			},

			issueLead: function (data) {
				try {
					var url = '/webapi/api/v1/leadprofile/issueLead';
					return ApiService.promisePost(url, data);
				} catch (e) {
					console.error('Error!', e);
				}
			},

			updateLead: function (data) {
				try {
					var url = '/webapi/api/v1/leadprofile/updateLead';
					return ApiService.promisePost(url, data);
				} catch (e) {
					console.error('Error!', e);
				}
			},

			updateLeadNotes: function (data) {
				try {
					var url = '/webapi/api/v1/leadprofile/updateLeadNotes';
					return ApiService.promisePost(url, data);
				} catch (e) {
					console.error('Error!', e);
				}
			},

			getDifficulty: function (id) {
				try {
					var url = '/webapi/api/v1/leadprofile/getDifficulty/' + id;
					return ApiService.promiseGet(url);
				} catch (e) {
					console.error('Error!', e);
				}
			},

			setDifficulty: function (data) {
				try {
					var url = '/webapi/api/v1/leadprofile/setDifficulty';
					return ApiService.promisePost(url, data);
				} catch (e) {
					console.error('Error!', e);
				}
			},

			removeAlternativeContactItem: function (data) {
				try {
					var url = '/webapi/api/v1/leadprofile/removeAlternativeContactItem';
					return ApiService.promisePost(url, data);
				} catch (e) {
					console.error('Error!', e);
				}
			},
			
			getFollowUpMail: function (data) {
				try {
					var url = '/webapi/api/v1/leadprofile/getFollowUpMail';
					return ApiService.promisePost(url, data);
				} catch (e) {
					console.log('Error!', e);
				}
			},

			getLeadProfilesForMailing: function (data) {
				try {
					var url = '/webapi/api/v1/leadProfile/getLeadProfilesForMailing';
					return ApiService.promisePost(url, data);
				} catch (e) {
					console.log('Error!', e);
				}
			},
			deleteSampleOrder: function (id,leadId) {
				try {
					var url = '/webapi/api/v1/leadprofile/deleteSampleOrder/' + id+'/'+leadId;
					return ApiService.promiseGet(url);
				}
				catch (e) {
					console.error('Error!', e);
				}
			},

			getLatLng: function (data) {
				try {
					var url = '/webapi/api/v1/leadProfile/getLatLng';
					return ApiService.promisePost(url, data);
				} catch (e) {
					console.log('Error!', e);
				}
			},

			CalculateLPPayment: function (data) {
				try {
					var url = '/webapi/api/v1/leadProfile/CalculateLPPayment';
					return ApiService.promisePost(url, data);
				} catch (e) {
					console.error('Error!', e);
				}
			},

			FetchLPCommissionDecimal: function (LPId) {
				try {
					var url = '/webapi/api/v1/leadProfile/FetchLPCommissionDecimal/'  + LPId;
					return ApiService.promiseGet(url);
				} catch (e) {
					console.error('Error!', e)
				}
			},

			ReturnToHOModal: function (data) {
				try {
					var url = '/webapi/api/v1/leadProfile/ReturnToHOModal';
					return ApiService.promisePost(url, data);
				} catch (e) {
					console.error('Error!', e);
				}
			},

			getIssuedDate: function (id) {
				try {
					var url = '/webapi/api/v1/leadProfile/getIssuedDate/' + id;
					return ApiService.promiseGet(url);
				} catch (e) {
					console.error('Error!', e);
				}
			},

			getConfigValue: function (key) {
				try {
					var url = '/webapi/api/v1/leadProfile/getConfigValue';
					return ApiService.promisePost(url, key);
				} catch (e) {
					console.error('Error!', e);
				}
			}
		};
		return leadProfileService;
	}]);
